'use client';

import viemClient from '@frontend/client/clients/viemClient';
import { isAddressOrEnsProvider } from '@frontend/client/hooks/useENSSearch';
import { cn } from '@frontend/utils/tailwindUtil';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar, AvatarProps, Skeleton } from '@radix-ui/themes';
import { useQuery } from '@tanstack/react-query';
import assert from 'assert';
import Image from 'next/image';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Address, isAddress } from 'viem';

// Constants
const SIZE_MAP = {
  '1': 16,
  '2': 32,
  '3': 48,
  '4': 64,
  '5': 80,
  '6': 96,
  '7': 128,
  '8': 160,
  '9': 192,
} as const;

const RADIUS_CLASS_MAP: Record<AvatarProps['radius'] & string, string> = {
  none: 'rounded-none',
  small: 'rounded-sm',
  medium: 'rounded-md',
  large: 'rounded-lg',
  full: 'rounded-full',
};

// Type definitions
type SizeMapKey = keyof typeof SIZE_MAP;
type AvatarSource = 'primary' | 'default';

interface UserAvatarProps {
  addressOrEns?: string | Address;
  fallbackIcon?: ReactElement;
  size?: AvatarProps['size'];
  radius?: AvatarProps['radius'];
  color?: AvatarProps['color'];
  className?: string;
}

// Helper functions
const getRadiusClass = (radius: AvatarProps['radius']): string => {
  return RADIUS_CLASS_MAP[radius as string] || 'rounded-full';
};

// Convert AvatarProps size to pixel value
const getSizeValue = (size: AvatarProps['size']): number => {
  if (typeof size === 'string' && size in SIZE_MAP) {
    return SIZE_MAP[size as SizeMapKey];
  }
  return SIZE_MAP['2']; // Default to size '2' (32px)
};

/**
 * UserAvatar component that displays an avatar for a user based on their Ethereum address or ENS name.
 * Falls back to default avatar if the ENS avatar fails.
 */
const UserAvatar: React.FC<UserAvatarProps> = ({
  addressOrEns: address,
  fallbackIcon,
  size = '2',
  color = 'gray',
  radius = 'full',
  className,
}) => {
  // Track which source to use for avatar
  const [avatarSource, setAvatarSource] = useState<AvatarSource>('primary');
  const isEns = isAddressOrEnsProvider(address);
  const sizeValue = getSizeValue(size);
  const radiusClass = getRadiusClass(radius);

  // ENS data fetching
  const { data: primaryName, isLoading: isEnsLoading } = useQuery({
    queryKey: ['ensName', address],
    queryFn: async () => {
      assert(isAddress(address!), 'Should be an address');
      const ens = await viemClient.getEnsFromAddress(address);
      return { ens, address };
    },
    enabled: !!address && isAddress(address),
    meta: { enablePersist: true },
  });

  // Create fallback icon with appropriate sizing
  const fallbackElement = useMemo(
    () =>
      fallbackIcon || (
        <PersonIcon
          style={{
            width: sizeValue * 0.75,
            height: sizeValue * 0.75,
          }}
        />
      ),
    [fallbackIcon, sizeValue],
  );

  // Primary ENS avatar URL
  const avatarUrl = useMemo(() => {
    if (!address || !isEns) return undefined;

    let identifier = address;
    if (isAddress(address)) {
      identifier = primaryName?.ens ?? address;
    }

    return `https://metadata.ens.domains/mainnet/avatar/${identifier}`;
  }, [address, isEns, primaryName?.ens]);

  // Reset avatar source when address changes
  useEffect(() => {
    setAvatarSource('primary');
  }, [address]);

  // Container style with dimensions
  const containerStyle = useMemo(
    () => ({
      width: sizeValue,
      height: sizeValue,
    }),
    [sizeValue],
  );

  // Handle image loading errors - go directly to default
  const handleImageError = useCallback(() => {
    setAvatarSource('default');
  }, []);

  // Component rendering
  return (
    <Skeleton loading={isEnsLoading && !!address && isAddress(address)}>
      <div
        className={cn('relative overflow-hidden', radiusClass, className)}
        style={containerStyle}
      >
        {avatarSource === 'primary' && avatarUrl ? (
          <Image
            src={avatarUrl}
            alt="User avatar"
            fill
            sizes={`${sizeValue}px`}
            priority
            onError={handleImageError}
            className="object-cover"
          />
        ) : (
          <Avatar
            fallback={fallbackElement}
            radius={radius}
            size={size}
            color={color}
            className={radiusClass}
            style={containerStyle}
          />
        )}
      </div>
    </Skeleton>
  );
};

export default React.memo(UserAvatar);
