import { safeWindow } from '@frontend/utils/safeWindow';
import { MOBILE_QUERY, useMediaQuery } from '@frontend/utils/UseMediaQuery';
import { Button, Flex, Grid, Text } from '@radix-ui/themes';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  WalletCoinbase,
  WalletPhantom,
  WalletWalletConnect,
} from '@web3icons/react';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { useConnect } from 'wagmi';
import { coinbaseWallet } from 'wagmi/connectors';

// Types
interface WalletConnectSectionProps {
  isReceiveContent?: boolean;
}

interface WalletButtonProps {
  icon: React.ReactNode;
  name: React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

// Helper functions
const getPhantomURL = () => {
  const url = encodeURIComponent(safeWindow?.location.href ?? '');
  const ref = encodeURIComponent('https://yodl.me');
  return `https://phantom.app/ul/browse/${url}?ref=${ref}`;
};

// Reusable Components
const WalletButton: FC<WalletButtonProps> = ({
  icon,
  name,
  onClick,
  loading = false,
  disabled = false,
  className = '',
}) => (
  <Button
    className={`!py-7 shadow-lg ${className}`}
    size="2"
    style={{ width: '100%' }}
    radius="large"
    color="gray"
    variant="outline"
    onClick={onClick}
    loading={loading}
    disabled={disabled}
  >
    <Flex align="center" gap="2" className="w-full">
      {icon}
      <Text size="3" weight="medium">
        {name}
      </Text>
    </Flex>
  </Button>
);

const SectionHeader: FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => (
  <Flex py="2">
    <Text size="1" mr="1" weight="medium">
      {title}
    </Text>
    {subtitle && (
      <Text size="1" color="gray">
        {subtitle}
      </Text>
    )}
  </Flex>
);

// Main Component
const WalletConnectSection: FC<WalletConnectSectionProps> = () => {
  const { connectors, connectAsync } = useConnect();
  const [pendingConnector, setPendingConnector] = useState<string | null>(null);
  const isMobile = useMediaQuery(MOBILE_QUERY);

  // Filter injected connectors without overly restrictive conditions
  const injectedConnectors = connectors
    .filter((c) => c.type === 'injected' && c.isAuthorized)
    .reduce(
      (unique, connector) => {
        const existing = unique.find((c) => c.name === connector.name);
        if (!existing || (!existing.icon && connector.icon)) {
          if (existing) {
            unique = unique.filter((c) => c.name !== existing.name);
          }
          unique.push(connector);
        }
        return unique;
      },
      [] as (typeof connectors)[number][],
    );

  const hasCoinbaseWallet = connectors.some(
    (c) => c.id === 'coinbaseWalletSDK',
  );

  // Connect handlers encapsulated with proper error handling
  const handleCoinbaseSmartWalletConnect = useCallback(async () => {
    setPendingConnector('coinbase-smart');

    try {
      await connectAsync({
        connector: coinbaseWallet({
          appName: 'yodl',
          preference: 'smartWalletOnly',
        }),
      });
    } catch (error) {
      console.error('Coinbase Smart Wallet connection failed:', error);
    } finally {
      setPendingConnector(null);
    }
  }, [connectAsync]);

  const handleCoinbaseWalletConnect = useCallback(async () => {
    setPendingConnector('coinbase');

    try {
      await connectAsync({
        connector: coinbaseWallet({
          appName: 'yodl',
          preference: 'eoaOnly',
        }),
      });
    } catch (error) {
      console.error('Coinbase Wallet connection failed:', error);
    } finally {
      setPendingConnector(null);
    }
  }, [connectAsync]);

  const handleWalletConnectModal = useCallback(
    (openConnectModal: () => void) => {
      setPendingConnector('walletconnect');

      const cleanup = () => setPendingConnector(null);

      // Observe modal removal
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.removedNodes.forEach((node) => {
            if ((node as Element).classList?.contains('rk-modal')) {
              cleanup();
              observer.disconnect();
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });

      // Cleanup after timeout or blur event
      safeWindow?.addEventListener('blur', cleanup, { once: true });
      const timeoutId = setTimeout(() => {
        cleanup();
        observer.disconnect();
      }, 300000);

      openConnectModal();
    },
    [],
  );

  const handlePhantomConnect = useCallback(() => {
    setPendingConnector('phantom');
    const phantomWindow = safeWindow?.open(getPhantomURL());

    const checkInterval = setInterval(() => {
      if (phantomWindow?.closed) {
        clearInterval(checkInterval);
        setPendingConnector(null);
      }
    }, 1000);

    // Cleanup after timeout
    setTimeout(() => {
      clearInterval(checkInterval);
      setPendingConnector(null);
    }, 300000);
  }, []);

  return (
    <Grid pb="3" gap="5">
      {/* Smart Wallets Section */}
      <Grid gap="2">
        <SectionHeader
          title="Smart Wallets"
          subtitle="gas free, best UX, 30s setup"
        />
        <WalletButton
          icon={<WalletCoinbase size={32} />}
          name={
            <>
              Smart Wallet
              <Text size="1" weight="medium" ml="1">
                by Coinbase
              </Text>
            </>
          }
          onClick={handleCoinbaseSmartWalletConnect}
          loading={pendingConnector === 'coinbase-smart'}
          disabled={
            pendingConnector !== null && pendingConnector !== 'coinbase-smart'
          }
        />
      </Grid>

      {/* Wallet Connect Section */}
      <Grid gap="2">
        <SectionHeader title="Existing Wallets" />
        <ConnectButton.Custom>
          {({ openConnectModal }) => (
            <WalletButton
              icon={<WalletWalletConnect size={32} />}
              name="Wallet Connect"
              onClick={() => handleWalletConnectModal(openConnectModal)}
              loading={pendingConnector === 'walletconnect'}
              disabled={
                pendingConnector !== null &&
                pendingConnector !== 'walletconnect'
              }
            />
          )}
        </ConnectButton.Custom>
      </Grid>

      {/* Phantom Mobile Wallet - Conditional Rendering */}
      {!injectedConnectors.some((c) => c.name === 'Phantom') && (
        <Grid gap="2" className="sm:!hidden">
          <SectionHeader title="Mobile Wallets" />
          <WalletButton
            icon={
              <WalletPhantom
                style={{ color: 'var(--accent-12)' }}
                size={32}
                variant="mono"
              />
            }
            name="Phantom"
            onClick={handlePhantomConnect}
            loading={pendingConnector === 'phantom'}
            disabled={
              pendingConnector !== null && pendingConnector !== 'phantom'
            }
          />
        </Grid>
      )}

      {/* Injected Browser Wallets */}
      {!isEmpty(injectedConnectors) && !isMobile && (
        <Grid gap="2">
          <SectionHeader title="Browser Wallets" />

          {injectedConnectors.map((connector) => (
            <WalletButton
              key={connector.id}
              icon={
                connector.icon ? (
                  <img
                    src={connector.icon}
                    alt={`${connector.name} Logo`}
                    width={32}
                    height={32}
                  />
                ) : null
              }
              name={connector.name}
              onClick={async () => {
                setPendingConnector(connector.id);
                try {
                  await connectAsync({ connector });
                } catch (error) {
                  console.error(`${connector.name} connection failed:`, error);
                } finally {
                  setPendingConnector(null);
                }
              }}
              loading={pendingConnector === connector.id}
              disabled={
                pendingConnector !== null && pendingConnector !== connector.id
              }
            />
          ))}

          {hasCoinbaseWallet && !isMobile && (
            <WalletButton
              icon={
                <WalletCoinbase
                  className="rounded-md"
                  variant="background"
                  size={32}
                />
              }
              name="Coinbase Wallet"
              onClick={handleCoinbaseWalletConnect}
              loading={pendingConnector === 'coinbase'}
              disabled={
                pendingConnector !== null && pendingConnector !== 'coinbase'
              }
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default WalletConnectSection;
