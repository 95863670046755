import { addEnsContracts } from '@ensdomains/ensjs';
import { defaultPublicRpc } from '@frontend/utils/env.client';
import { primaryName } from '@justaname.id/hybrid-primary-name';
import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';

const viemClient = createPublicClient({
  chain: addEnsContracts(mainnet),
  transport: http(defaultPublicRpc(1), { batch: true }),
}).extend(primaryName());

export default viemClient;
